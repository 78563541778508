import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import FAQItem from "../../components/FAQ/FAQItem"
import SectionHeading from "../../components/SectionHeading"
import ContentContainer from "../../templates/ContentContainer"

const FAQ = () => {
    const { formatMessage } = useIntl()
    const renderFAQItems = () => {
        const FAQItems = []
        let index = 0
        while (
            formatMessage({
                id: `nodeHosting.FAQ.questions.${index}.question`,
            }) !== `nodeHosting.FAQ.questions.${index}.question`
        ) {
            FAQItems.push(
                <FAQItem
                    key={index}
                    question={formatMessage({
                        id: `nodeHosting.FAQ.questions.${index}.question`,
                    })}
                    answer={formatMessage(
                        {
                            id: `nodeHosting.FAQ.questions.${index}.answer`,
                        },
                        { br: <br /> }
                    )}
                />
            )
            index += 1
        }
        return FAQItems.map((item) => item)
    }
    return (
        <section>
            <ContentContainer>
                <SectionHeading>
                    {formatMessage({ id: "nodeHosting.FAQ.sectionHeading" })}
                </SectionHeading>
                {renderFAQItems()}
                <p className="text-sm text-white mt-10">
                    {formatMessage({ id: "nodeHosting.FAQ.report" })}
                    {` `}
                    <a className="text-links" href="mailto:info@horizen.io">
                        info@horizen.io
                    </a>
                </p>
            </ContentContainer>
        </section>
    )
}

export default FAQ
