import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Button from "../../components/Button"
import { StepList } from "../../components/NodeHosting/StepList"
import ContentContainer from "../../templates/ContentContainer"

const Steps = () => {
    const { formatMessage } = useIntl()

    return (
        <section className="z-[1] relative">
            <ContentContainer>
                <StepList />
                <p className="leading-6 text-horizen-grey pt-5">
                    {formatMessage({ id: "nodeHosting.steps.disclaimer" })}
                </p>
                <div className="flex justify-center pt-10">
                    <Button
                        href={formatMessage({
                            id: "nodeHosting.steps.button.link",
                        })}
                        label={formatMessage({
                            id: "nodeHosting.steps.button.label",
                        })}
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default Steps
