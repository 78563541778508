import React from "react"
import NewsletterForm from "../../components/NewsletterForm"
import FAQ from "./FAQ"

import LatestUpdatesBackground from "../../assets/LatestUpdatesBackground.png"

const FAQWithNewsletterForm = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${LatestUpdatesBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% center",
            }}
        >
            <div className="pt-44">
                <FAQ />
            </div>
            <div className="pt-36">
                <NewsletterForm />
            </div>
        </div>
    )
}

export default FAQWithNewsletterForm
