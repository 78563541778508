import React from "react"
import EarningsCalculator from "./EarningsCalculator"
import Steps from "./Steps"

import bgImg from "../../assets/BackgroundImages/NodeHosting/Horizen_NodeHosting_main2.png"

const EarningsCalculatorAndSteps = () => {
    return (
        <div>
            <div className="pt-44">
                <EarningsCalculator />
            </div>
            <div className="pt-44 relative">
                <Steps />
                <div
                    className="h-[621px] absolute w-full -top-[150px] z-0"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "50% center",
                    }}
                />
            </div>
        </div>
    )
}

export default EarningsCalculatorAndSteps
