import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React, { useState } from "react"
import useClickAway from "../../utils/useClickAway"
import MinusIcon from "../MinusIcon"
import Typography from "../Typography"

interface ProviderListMenuItemProps {
    onClick: () => void
    href: string
}

const ProviderListMenuItem: React.FC<ProviderListMenuItemProps> = ({
    children,
    onClick,
    href,
}) => {
    return (
        <a
            href={href}
            target="_blank"
            className="transition-all block py-6 px-5 bg-horizen-hp-bg backdrop-blur-[4px] border border-transparent 
            hover:[background:linear-gradient(150deg,rgba(12,12,28,0.3)_47.38%,#26DB8D_190%)] hover:border-links 
            focus:[background:linear-gradient(150deg,rgba(12,12,28,0.3)_47.38%,#26DB8D_190%)] focus:border-links
            w-full
        "
            onClick={onClick}
            rel="noreferrer"
        >
            <Typography.Body2 className="!text-white">
                {children}
            </Typography.Body2>
        </a>
    )
}

const ProviderListMenu: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false)
    const openMenu = () => setShowMenu(true)
    const closeMenu = () => setShowMenu(false)
    const ref = useClickAway<HTMLDivElement>(closeMenu)

    const { formatMessage } = useIntl()

    const renderProviderListMenuItem = () => {
        const providerListLength = formatMessage({
            id: "nodeHosting.steps.items.2.providerListLength",
        })

        return [...Array(Number(providerListLength))].map((_, index) => {
            const baseID = `nodeHosting.steps.items.2.providerList.${index}`
            return (
                <ProviderListMenuItem
                    onClick={closeMenu}
                    href={formatMessage({
                        id: `${baseID}.link`,
                    })}
                    key={baseID}
                >
                    <FormattedMessage id={`${baseID}.text`} />
                </ProviderListMenuItem>
            )
        })
    }

    return (
        <div className="relative w-full">
            <button
                className="text-links block mt-2 text-left text-[22px] font-bold"
                type="button"
                onClick={openMenu}
            >
                <FormattedMessage id="nodeHosting.steps.items.2.providerListButtonText" />
            </button>
            <div
                className="transition-opacity absolute z-10 mt-2 left-0 flex flex-col w-full bg-horizen-hp-bg border-[1px] border-white/50"
                ref={ref}
                style={{ display: showMenu ? "flex" : "none" }}
            >
                <button
                    className="absolute z-10 top-[-12.5px] right-[-12.5px]"
                    onClick={closeMenu}
                    type="button"
                >
                    <MinusIcon />
                </button>

                {renderProviderListMenuItem()}
            </div>
        </div>
    )
}

export { ProviderListMenu, ProviderListMenuItem }
