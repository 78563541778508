import React from "react"

const Box: React.FC = ({ children }) => {
    return (
        <div className="border border-horizen-grey pl-8 py-6 pr-6 text-white flex flex-col min-h-[225px] lg:min-h-[290px] bg-[rgba(12,12,28,0.3)]">
            {children}
        </div>
    )
}

const BoxHeader: React.FC = ({ children }) => {
    return (
        <div className="block tracking-[0.2em] text-horizen-grey text-sm font-bold">
            {children}
        </div>
    )
}
const BoxTitle: React.FC = ({ children }) => {
    return (
        <div className="block text-[22px] font-bold my-2 mb-auto">
            {children}
        </div>
    )
}

export type BoxLinkProps = {
    href: string
    onClick?: () => void
    variant?: "primary" | "secondary"
}

const BoxLink: React.FC<BoxLinkProps> = ({
    href,
    variant = "secondary",
    onClick,
    children,
}) => {
    return (
        <a
            className={`${
                variant === "primary" ? "text-[22px] font-bold" : ""
            } text-links block mt-2 text-left`}
            href={href}
            onClick={onClick}
        >
            {variant === "primary" ? children : <>[ {children} ]</>}
        </a>
    )
}

export { Box, BoxHeader, BoxTitle, BoxLink }
