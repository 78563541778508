import React from "react"
import { Helmet } from "react-helmet"

const NodeHostingHelmet = () => {
    return (
        <Helmet>
            <title>ZenNode Hosting Guide - Horizen</title>
            <meta
                property="og:title"
                content="ZenNode Hosting Guide - Horizen"
            />
            <meta
                name="twitter:title"
                content="ZenNode Hosting Guide - Horizen"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Learn how to host a super node, payouts in the form of block rewards."
            />
            <meta
                property="og:description"
                content="Learn how to host a super node, payouts in the form of block rewards."
            />
            <meta
                name="twitter:description"
                content="Learn how to host a super node, payouts in the form of block rewards."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/nodehosting.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/nodehosting.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="zennode hosting guide, zen node hosting, zennode hosting"
            />
            <link rel="canonical" href="https://www.horizen.io/node-hosting/" />
            <meta
                property="og:url"
                content="https://www.horizen.io/node-hosting/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default NodeHostingHelmet
