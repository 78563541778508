import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import NormalPageHero from "../../components/NormalPageHero"

import * as styles from "../../styles/node-hosting/node-hosting.module.css"

const NodeHostingHero = () => {
    const { formatMessage } = useIntl()
    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.nodeHostingHero}`}
        >
            <NormalPageHero
                titleKey={formatMessage({ id: "nodeHosting.hero.title" })}
                subtitleKey={formatMessage({ id: "nodeHosting.hero.p1" })}
            />
        </section>
    )
}

export default NodeHostingHero
