import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import SectionHeading from "../../components/SectionHeading"
import ContentContainer from "../../templates/ContentContainer"

const ZenNode = () => {
    const { formatMessage } = useIntl()
    return (
        <section>
            <ContentContainer>
                <div
                    className="lg:py-7 space-y-4 lg:space-y-0 lg:grid lg:grid-cols-3 relative lg:before:absolute lg:before:w-[14px] lg:before:h-full lg:before:border lg:before:border-r-0
lg:after:absolute lg:after:w-[14px] lg:after:h-full lg:after:border lg:after:border-l-0 lg:after:right-0"
                >
                    <div className="lg:pl-16 xl:pl-24 flex items-center h-full">
                        <SectionHeading>
                            {formatMessage({
                                id: "nodeHosting.zenNode.sectionHeading",
                            })}
                        </SectionHeading>
                    </div>
                    <div className="col-span-2">
                        <p className="text-horizen-content-grey text-base md:text-lg mt-2 mb-6 max-w-2xl lg:pr-4">
                            {formatMessage({ id: "nodeHosting.zenNode.p1" })}
                        </p>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default ZenNode
