import React from "react"
import NodeHostingHelmet from "../components/SEO/NodeHostingHelmet"
import EarningsCalculatorAndSteps from "../sections/node-hosting/EarningsCalculatorAndSteps"
import FAQWithNewsletterForm from "../sections/node-hosting/FAQWithNewsletterForm"
import NodeHostingHero from "../sections/node-hosting/NodeHostingHero"
import ZenNode from "../sections/node-hosting/ZenNode"
import Layout from "../templates/Layout"

const NodeHosting = () => {
    return (
        <Layout>
            <NodeHostingHelmet />
            <div className="bg-horizen-hp-bg">
                <NodeHostingHero />
                <ZenNode />
                <EarningsCalculatorAndSteps />
                <FAQWithNewsletterForm />
            </div>
        </Layout>
    )
}

export default NodeHosting
