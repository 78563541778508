import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Box, BoxHeader, BoxLink, BoxTitle } from "./Box"
import { ProviderListMenu } from "./ProviderListMenu"

const Step1 = () => {
    const { formatMessage } = useIntl()

    return (
        <Box>
            <BoxHeader>
                <FormattedMessage id="nodeHosting.steps.items.0.stepHeading" />
            </BoxHeader>
            <BoxTitle>
                <FormattedMessage id="nodeHosting.steps.items.0.stepTitle" />
            </BoxTitle>
            <BoxLink
                href={formatMessage({
                    id: "nodeHosting.steps.items.0.primaryLinks.0.link",
                })}
                variant="primary"
            >
                <FormattedMessage id="nodeHosting.steps.items.0.primaryLinks.0.label" />
            </BoxLink>
            <BoxLink
                href={formatMessage({
                    id: "nodeHosting.steps.items.0.secondaryLinks.0.link",
                })}
                variant="secondary"
            >
                <FormattedMessage id="nodeHosting.steps.items.0.secondaryLinks.0.label" />
            </BoxLink>
        </Box>
    )
}

const Step2 = () => {
    const { formatMessage } = useIntl()

    return (
        <Box>
            <BoxHeader>
                <FormattedMessage id="nodeHosting.steps.items.1.stepHeading" />
            </BoxHeader>
            <BoxTitle>
                <FormattedMessage id="nodeHosting.steps.items.1.stepTitle" />
            </BoxTitle>
            <BoxLink
                href={formatMessage({
                    id: "nodeHosting.steps.items.1.primaryLinks.0.link",
                })}
                variant="primary"
            >
                <FormattedMessage id="nodeHosting.steps.items.1.primaryLinks.0.label" />
            </BoxLink>
            <BoxLink
                href={formatMessage({
                    id: "nodeHosting.steps.items.1.secondaryLinks.0.link",
                })}
                variant="secondary"
            >
                <FormattedMessage id="nodeHosting.steps.items.1.secondaryLinks.0.label" />
            </BoxLink>
        </Box>
    )
}

const Step3 = () => {
    return (
        <Box>
            <BoxHeader>
                <FormattedMessage id="nodeHosting.steps.items.2.stepHeading" />
            </BoxHeader>
            <BoxTitle>
                <FormattedMessage id="nodeHosting.steps.items.2.stepTitle" />
            </BoxTitle>
            <ProviderListMenu />
        </Box>
    )
}

const StepList = () => {
    return (
        <div className="grid lg:grid-cols-3 gap-6">
            <Step1 />
            <Step2 />
            <Step3 />
        </div>
    )
}

export { StepList }
