import { useLayoutEffect, useRef } from "react"

const useClickAway = <T extends HTMLElement>(callBck: () => void) => {
    const ref = useRef<T>(null)

    useLayoutEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callBck()
            }
        }

        document.addEventListener("click", handleClick, true)

        return () => document.removeEventListener("click", handleClick, true)
    }, [ref, callBck])

    return ref
}

export default useClickAway
