import axios from "axios"
import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useMemo, useState } from "react"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

import moment from "moment"
import SectionHeading from "../../components/SectionHeading"
import ContentContainer from "../../templates/ContentContainer"
import numberWithCommas from "../../utils/numberWithCommas"
import { URLProvider } from "../../utils/URLProvider"

const Icon = () => (
    <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2077_46)">
            <path
                d="M70 35C70.0005 28.0773 67.9481 21.3099 64.1022 15.5537C60.2564 9.79754 54.79 5.31112 48.3943 2.66184C41.9985 0.0125656 34.9608 -0.680556 28.1711 0.670136C21.3815 2.02083 15.1448 5.35466 10.25 10.25L19 19L20.09 20.09C24.0404 16.1396 29.3983 13.9203 34.985 13.9203C40.5717 13.9203 45.9296 16.1396 49.88 20.09C53.8304 24.0404 56.0497 29.3983 56.0497 34.985C56.0497 40.5717 53.8304 45.9296 49.88 49.88L51 51L59.78 59.78C63.0312 56.526 65.6077 52.6618 67.3616 48.4094C69.1154 44.1569 70.012 39.5999 70 35Z"
                fill="url(#paint0_radial_2077_46)"
            />
            <path
                d="M49.8798 49.88C45.9334 53.8264 40.5809 56.0435 34.9998 56.0435C29.4188 56.0435 24.0663 53.8264 20.1198 49.88C16.1734 45.9336 13.9563 40.5811 13.9563 35C13.9563 29.4189 16.1734 24.0664 20.1198 20.12L10.2498 10.25C3.68572 16.8141 -0.00195312 25.7169 -0.00195312 35C-0.00195312 44.2831 3.68572 53.1859 10.2498 59.75C16.8139 66.3141 25.7168 70.0018 34.9998 70.0018C44.2829 70.0018 53.1857 66.3141 59.7498 59.75L49.8798 49.88Z"
                fill="white"
            />
            <path
                d="M35.0002 25.5C32.5958 25.5263 30.2899 26.4586 28.5428 28.1108C26.7958 29.763 25.7363 32.0133 25.576 34.4125C25.4156 36.8117 26.1662 39.183 27.6778 41.053C29.1894 42.923 31.3507 44.1539 33.7302 44.5V39.13C32.7553 38.8263 31.9214 38.1832 31.38 37.3174C30.8385 36.4516 30.6252 35.4205 30.7788 34.4109C30.9324 33.4014 31.4427 32.4803 32.2172 31.8148C32.9917 31.1492 33.9791 30.7832 35.0002 30.7832C36.0214 30.7832 37.0087 31.1492 37.7832 31.8148C38.5577 32.4803 39.068 33.4014 39.2216 34.4109C39.3752 35.4205 39.162 36.4516 38.6205 37.3174C38.079 38.1832 37.2452 38.8263 36.2702 39.13V44.5C38.6497 44.1539 40.8111 42.923 42.3227 41.053C43.8343 39.183 44.5848 36.8117 44.4245 34.4125C44.2641 32.0133 43.2046 29.763 41.4576 28.1108C39.7106 26.4586 37.4046 25.5263 35.0002 25.5Z"
                fill="white"
            />
        </g>
        <defs>
            <radialGradient
                id="paint0_radial_2077_46"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(58.01 54.12) scale(58.09)"
            >
                <stop offset="0.1" stopColor="#26DB8D" />
                <stop offset="0.26" stopColor="#23D29A" />
                <stop offset="0.56" stopColor="#1ABBBB" />
                <stop offset="0.89" stopColor="#0E9DE5" />
            </radialGradient>
            <clipPath id="clip0_2077_46">
                <rect width="70" height="70" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const EarningsCalculator = () => {
    const { formatMessage } = useIntl()
    const dropdownOptions = useMemo(
        () => [
            {
                value: "super",
                label: formatMessage({
                    id: "nodeHosting.earningsCalculator.superNode",
                }),
            },
        ],
        [formatMessage]
    )
    const defaultOption = useMemo(() => dropdownOptions[0], [dropdownOptions])
    const [dropdownSelectedItem, setDropdownSelectedItem] = useState(
        dropdownOptions[0].value
    )

    const [superNodesCount, setSuperNodesCount] = useState(0)

    const amountOfZEN = 500

    const [rewardPerMonth, setRewardPerMonth] = useState("")
    const [rewardPerYear, setRewardPerYear] = useState("")
    const [zenPrice, setZenPrice] = useState(0)

    const [superReward, setSuperReward] = useState(0)

    useEffect(() => {
        let reward = 0
        switch (dropdownSelectedItem) {
            case "super":
                reward = superReward
                break
            default:
                reward = superReward
                break
        }

        setRewardPerMonth(Number(reward * zenPrice * 30).toFixed(2))
        setRewardPerYear(Number(reward * zenPrice * 365).toFixed(2))
    }, [dropdownSelectedItem, zenPrice, superReward])

    useEffect(() => {
        axios.get(URLProvider.URL_SUPERNODES_API).then((res) => {
            setSuperNodesCount(res.data.global.up)
            setSuperReward(res.data.actualearn)
        })
        axios
            .get(
                `https://api.coingecko.com/api/v3/coins/zencash/history?localization=false
                &date=${moment().format("DD-MM-YYYY")}`
            )
            .then((res) => {
                setZenPrice(res.data.market_data.current_price.usd.toFixed(2))
            })
    }, [])

    return (
        <section className="text-white relative z-[1]">
            <ContentContainer>
                <div className="grid md:grid-cols-3 gap-6">
                    <div className="flex flex-col space-y-3">
                        <Icon />
                        <span className="font-bold text-[55px] leading-[67px]">
                            {numberWithCommas(superNodesCount)}
                        </span>
                        <span className="font-bold text-lg text-horizen-content-grey leading-6">
                            {formatMessage({
                                id: "nodeHosting.earningsCalculator.totalNodes",
                            })}
                        </span>
                        <a
                            href="https://supernodes1.eu.zensystem.io/"
                            className="text-links"
                        >
                            [{" "}
                            {formatMessage({
                                id: "nodeHosting.earningsCalculator.superNodeTracker",
                            })}{" "}
                            ]
                        </a>
                    </div>
                    <div className="col-span-2">
                        <SectionHeading>
                            {formatMessage({
                                id: "nodeHosting.earningsCalculator.sectionHeading",
                            })}
                        </SectionHeading>
                        <div className="p-6 border border-b-0 grid md:grid-cols-3 gap-y-9 items-center mt-4">
                            <span className="text-horizen-content-grey text-lg">
                                {formatMessage({
                                    id: "nodeHosting.earningsCalculator.chooseNode",
                                })}
                            </span>
                            <div className="col-span-2">
                                <Dropdown
                                    className="bg"
                                    controlClassName="!text-white font-bold text-lg !bg-transparent"
                                    options={dropdownOptions}
                                    onChange={(option) => {
                                        setDropdownSelectedItem(option.value)
                                    }}
                                    value={defaultOption}
                                    placeholder="Select an option"
                                />
                            </div>
                            <span className="text-horizen-content-grey text-lg">
                                {formatMessage({
                                    id: "nodeHosting.earningsCalculator.ZENstakeRequired",
                                })}
                            </span>
                            <div className="col-span-2 font-bold text-lg">
                                {amountOfZEN} ZEN
                            </div>
                            <span className="text-horizen-content-grey text-lg">
                                {formatMessage({
                                    id: "nodeHosting.earningsCalculator.ZENprice",
                                })}
                            </span>
                            <div className="col-span-2">
                                <input
                                    type="number"
                                    className="text-lg font-bold w-full bg-transparent border focus:border outline-none p-2"
                                    value={zenPrice}
                                    onChange={(e) =>
                                        setZenPrice(
                                            Math.abs(
                                                parseFloat(e.target.value)
                                            ) || 0
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="p-6 border border-links grid md:grid-cols-3 items-center">
                            <p className="text-lg max-w-[180px]">
                                {formatMessage({
                                    id: "nodeHosting.earningsCalculator.estimatedEarnings",
                                })}
                            </p>
                            <div>
                                <span className="font-bold text-[41px] block">
                                    ${numberWithCommas(rewardPerMonth)}
                                </span>
                                <span className="text-horizen-grey tracking-[0.2em] font-bold text-xs">
                                    {formatMessage({
                                        id: "nodeHosting.earningsCalculator.perMonth",
                                    })}
                                </span>
                            </div>
                            <div>
                                <span className="font-bold text-[41px] block">
                                    ${numberWithCommas(rewardPerYear)}
                                </span>
                                <span className="text-horizen-grey tracking-[0.2em] font-bold text-xs">
                                    {formatMessage({
                                        id: "nodeHosting.earningsCalculator.perYear",
                                    })}
                                </span>
                            </div>
                        </div>
                        <p className="text-xs text-horizen-grey mt-4">
                            {formatMessage({
                                id: "nodeHosting.earningsCalculator.disclaimer",
                            })}
                        </p>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default EarningsCalculator
