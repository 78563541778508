import React, { useState } from "react"
import CollapsableArrow from "./CollapsableArrow"

type FAQItemProps = {
    question: string
    answer: string | React.ReactNode
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
    const [active, setActive] = useState(false)
    return (
        <div
            className="text-white"
            onClick={() => setActive(!active)}
            onKeyPress={() => setActive(!active)}
            role="button"
            tabIndex={0}
        >
            <div className="font-bold text-[22px] border-b py-4 flex items-center justify-between">
                <div>{question}</div>
                <div>
                    <CollapsableArrow active={active} />
                </div>
            </div>
            <div
                className={`transition-[padding] duration-500  ${
                    active ? "py-10" : "max-h-0 overflow-hidden"
                }`}
            >
                {answer}
            </div>
        </div>
    )
}

export default FAQItem
