import React from "react"

const CollapsableArrow = ({ active }: { active: boolean }) => {
    return (
        <div className="h-5 w-5 relative">
            <svg
                className={`absolute top-1/3 transition-all duration-500 ${
                    active ? "opacity-100 rotate-0" : "opacity-0 rotate-180"
                }`}
                width="22"
                height="11"
                viewBox="0 0 22 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.2129 10.6067H-0.000310659L10.6063 8.88109e-05L21.2129 10.6067Z"
                    fill="#0E9DE5"
                />
            </svg>

            <svg
                className={`absolute top-1/3 transition-all duration-500 ${
                    active ? "opacity-0 -rotate-180" : "opacity-100 rotate-0"
                }`}
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 0.606689H21.2132L10.6066 11.2133L0 0.606689Z"
                    fill="#0E9DE5"
                />
            </svg>
        </div>
    )
}

export default CollapsableArrow
